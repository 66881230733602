<template>
    <comp-table title="导购点分类管理" :columns="columns" :table-api="`/gateway/apps/o2o/api/terminal/category/queryAllCategoryByPage?terminalGroupId=${$core.getUrlParam('t')}`">
        <template v-slot:search="data">
            <Input v-model.trim="data.search.supplierName" placeholder="输入供应商名称" style="width: 200px"></Input>
            <Select v-model="data.search.terminalCategoryId" style="width:200px" placeholder="选择一级分类">
                <Option v-for="item in primarys" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
        </template>
        <template #operate>
            <Button type="primary" @click="Route.jump('/guidecategoryprimary?t=' + $core.getUrlParam('t'))">一级分类设置</Button>
        </template>
    </comp-table>
</template>

<script>
import Route from "../../../communitymobilization/entity/Route"
import CompTable from "../../components/comp-table.vue"
import Request from "../../utils/request"

export default {
    components: { CompTable },

    data() {
        return {
            Route: Route,
            // 表格列
            columns: null,
            // 一级分类
            primarys: [],
        }
    },

    watch: {
        primarys(v) {
            this.columns = [
                {
                    title: "供应商名称",
                    key: "supplierName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "店内商品分类",
                    key: "supplierCategoryName",
                    minWidth: 300,
                    align: "center",
                },
                {
                    title: "一级分类",
                    key: "labelName",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "Select",
                            {
                                style: {
                                    width: "200px",
                                },
                                props: {
                                    value: params.row.terminalCategoryId,
                                },
                                on: {
                                    "on-change": v => {
                                        if (typeof v !== "undefined") {
                                            this.onBindPrimary(params.row, v)
                                        }
                                    },
                                },
                            },
                            v.map(item => {
                                return h(
                                    "Option",
                                    {
                                        props: {
                                            value: item.id,
                                        },
                                    },
                                    item.name
                                )
                            })
                        )
                    },
                },
            ]
        },
    },

    created() {
        this.getAllPrimary()
    },

    methods: {
        getAllPrimary() {
            Request.get("/gateway/apps/o2o/api/terminal/category/queryByNoPage", {
                terminalGroupId: this.$core.getUrlParam("t"),
            }).then(res => {
                this.primarys = res
            })
        },

        onBindPrimary(row, v) {
            Request.post(
                "/gateway/apps/o2o/api/terminal/category/saveSupplierCategory",
                {
                    terminalGroupId: this.$core.getUrlParam("t"),
                    terminalCategoryId: v,
                    supplierName: row.supplierName,
                    supplierId: row.supplierId,
                    supplierCategoryId: row.supplierCategoryId,
                    supplierCategoryName: row.supplierCategoryName,
                },
                { json: true }
            ).then(() => {
                this.$Message.success("绑定成功")
            })
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
</style>
